<template>
  <div id="more">
    <top :name="text" />
    <van-tabs @click="onClick" v-model="activeName" animated swipeable>
      <van-tab title="拼团订单" name="all">
        <van-list
          :immediate-check="ischeck"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            style="
              width: 96%;
              background: white;
              padding: 0.1rem;
              height: auto;
              margin: 0.1rem auto 0;
              border-radius: 0.2rem;
            "
            v-for="(item, index) in json_data"
            :key="index"
            @click="order(item.commodity_id, item.order_number)"
          >
            <p
              style="
                font-size: 0.26rem;
                margin: 0 0 0 0.2rem;
                color: red;
                text-align: right;
              "
            >
              {{ item.static_bootey }}
            </p>
            <div class="shopping">
              <div class="a">
                <img class="imghhh" :src="item.image_sku" alt="" />
              </div>
              <div style="position: relative; flex: 1.6">
                <p
                  class="bn"
                  style="
                    font-size: 0.24rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ item.commodity_name }}
                </p>
                <p class="bn" style="font-size: 0.2rem; color: gray">
                  {{ item.commodity_sku }}
                </p>
                <div class="zuoyou">
                  <p style="color: red; font-size: 0.26rem">
                    实付:￥{{ item.actual_price }}
                  </p>
                  <p>*1</p>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="拼团中" name="1">
        <van-list
          :immediate-check="ischeck"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            style="
              width: 96%;
              background: white;
              padding: 0.1rem;
              height: auto;
              margin: 0.1rem auto 0;
              border-radius: 0.2rem;
            "
            v-for="(item, index) in json_data"
            :key="index"
            @click="order(item.commodity_id, item.order_number)"
          >
            <p
              style="
                font-size: 0.26rem;
                margin: 0 0 0 0.2rem;
                color: red;
                text-align: right;
              "
            >
              {{ item.static_bootey }}
            </p>
            <div class="shopping">
              <div class="a">
                <img class="imghhh" :src="item.image_sku" alt="" />
              </div>
              <div style="position: relative; flex: 1.6">
                <p
                  class="bn"
                  style="
                    font-size: 0.24rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ item.commodity_name }}
                </p>
                <p class="bn" style="font-size: 0.2rem; color: gray">
                  {{ item.commodity_sku }}
                </p>
                <div class="zuoyou">
                  <p style="color: red; font-size: 0.26rem">
                    实付:￥{{ item.actual_price }}
                  </p>
                  <p>*1</p>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="拼团完成" name="2">
        <van-list
          :immediate-check="ischeck"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            style="
              width: 96%;
              background: white;
              padding: 0.1rem;
              height: auto;
              margin: 0.1rem auto 0;
              border-radius: 0.2rem;
            "
            v-for="(item, index) in json_data"
            :key="index"
            @click="order(item.commodity_id, item.order_number)"
          >
            <p
              style="
                font-size: 0.26rem;
                margin: 0 0 0 0.2rem;
                color: red;
                text-align: right;
              "
            >
              {{ item.static_bootey }}
            </p>
            <div class="shopping">
              <div class="a">
                <img class="imghhh" :src="item.image_sku" alt="" />
              </div>
              <div style="position: relative; flex: 1.6">
                <p
                  class="bn"
                  style="
                    font-size: 0.24rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ item.commodity_name }}
                </p>
                <p class="bn" style="font-size: 0.2rem; color: gray">
                  {{ item.commodity_sku }}
                </p>
                <div class="zuoyou">
                  <p style="color: red; font-size: 0.26rem">
                    实付:￥{{ item.actual_price }}
                  </p>
                  <p>*1</p>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="特殊订单" name="4">
        <van-list
          :immediate-check="ischeck"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            style="
              width: 96%;
              background: white;
              padding: 0.1rem;
              height: auto;
              margin: 0.1rem auto 0;
              border-radius: 0.2rem;
            "
            v-for="(item, index) in json_data"
            :key="index"
            @click="order(item.commodity_id, item.order_number)"
          >
            <p
              style="
                font-size: 0.26rem;
                margin: 0 0 0 0.2rem;
                color: red;
                text-align: right;
              "
            >
              {{ item.static_bootey }}
            </p>
            <div class="shopping">
              <div class="a">
                <img class="imghhh" :src="item.image_sku" alt="" />
              </div>
              <div style="position: relative; flex: 1.6">
                <p
                  class="bn"
                  style="
                    font-size: 0.24rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  "
                >
                  {{ item.commodity_name }}
                </p>
                <p class="bn" style="font-size: 0.2rem; color: gray">
                  {{ item.commodity_sku }}
                </p>
                <div class="zuoyou">
                  <p style="color: red; font-size: 0.26rem">
                    实付:￥{{ item.actual_price }}
                  </p>
                  <p>*1</p>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script lang="ts">
import top from "../components/filltop.vue";

export default {
  data() {
    return {
      text: "我的拼团",
      activeName: "",
      page: 1,
      // 滚动参数
      ischeck: false,
      loading: false,
      finished: false,
      // 滚动参数 End
      json_data: {},
      // 切换防止滚动加载
      tabchange: false,
    };
  },
  components: {
    top,
  },
  methods: {
    // 订单详情查询
    order(id, order) {
      // console.log("id:",id,"order:",order)
      this.$https(
        "order/ressover",
        "id=" +
          sessionStorage.getItem("user_id") +
          "&phone=" +
          sessionStorage.getItem("user_phone") +
          "&commodity_id=" +
          id +
          "&order_number=" +
          order,
        "post"
      ).then((res) => {
        if (res.data.code == "000000") {
          // console.log(JSON.stringify(res.data.data));
          this.$router.push({
            name: "collagetobayto",
            params: {
              success_data: JSON.stringify(res.data.data),
            },
          });
        } else {
          this.$toast.fail(res.data.msg);
        }
      });
    },
    // 滚动条触发时间
    onLoad() {
      this.loading = true;
      this.page = this.page + 1;
      console.log("触发", this.page);
      setTimeout(() => {
        if (this.tabchange == false) {
          this.$https(
            "collage/collageList",
            "id=" +
              sessionStorage.getItem("user_id") +
              "&phone=" +
              sessionStorage.getItem("user_phone") +
              "&mallType=" +
              this.activeName +
              "&page=" +
              this.page,
            "post"
          )
            .then((res) => {
              if (res.data.data.length >= 1) {
                res.data.data.forEach((item, index) => {
                  this.json_data.push(item);
                });
                this.loading = false;
              }
              if (res.data.data.length < 10) {
                this.finished = true;
              }
              this.static(res);
            })
            .catch((e) => {
              console.log("错误报文", e);
            });
        }
        this.tabchange = false;
      }, 500);
    },
    onClick(e) {
      // 切换标签时 page初始化
      (this.page = 1), (this.finished = false), (this.tabchange = true);
      this.loading = true;
      // 记录点击的标签 订单详情返回时用到
      sessionStorage.setItem("dingdan_activename", this.activeName);
      this.https(e);
    },
    https(e) {
      this.$https(
        "collage/collageList",
        "id=" +
          sessionStorage.getItem("user_id") +
          "&phone=" +
          sessionStorage.getItem("user_phone") +
          "&mallType=" +
          e +
          "&page=" +
          this.page,
        "post"
      ).then((res) => {
        res.data.code == "000000"
          ? ((this.json_data = res.data.data),
            this.static(res),
            (this.tabchange = false))
          : this.$toast.fail("请重新进入");
        this.loading = false;
        if (res.data.data.length < 10) {
          this.finished = true;
        }
      });
    },
    static(res) {
      console.log(res);
      // 改变状态码
      if (res.data.data.length > 0)
        this.json_data.forEach((item, index) => {
          "1" == item.static_bootey && (item.static_bootey = "拼团中");
          "2" == item.static_bootey && (item.static_bootey = "等待开奖");
          "3" == item.static_bootey && (item.static_bootey = "已开奖");
          "4" == item.static_bootey && (item.static_bootey = "退款订单");
        });
    },
  },
  mounted() {
    // // 记录点击的标签 订单详情返回时用到
    sessionStorage.setItem("dingdan_activename", this.activeName);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // vm.$refs.box.scrolltop = 10;
      if (from.name == "personalcenter") {
        vm.activeName = vm.$route.query.id;
        // 从个人中心进入
        vm.https(vm.activeName);
      }
      if (from.name == "collagetobayto") {
        // 从团购详情返回 page初始化为1，标签映射页面回显
        (vm.page = 1),
        (vm.activeName = sessionStorage.getItem("dingdan_activename"));
        vm.$https(
          "collage/collageList",
          "id=" +
            sessionStorage.getItem("user_id") +
            "&phone=" +
            sessionStorage.getItem("user_phone") +
            "&mallType=" +
            vm.activeName +
            "&page=" +
            vm.page,
          "post",
          'false'
        ).then(res=>{
        if(res.data.code == '000000'){
          // 赋值订单列表信息
          vm.json_data = res.data.data;
          // 同步调用状态函数
          vm.static(res)
        }else{
          this.$toast.fail(res.data.msg)
        }

        })
      }
    });
  },
};
</script>

<style scoped>
#more {
  height: auto;
  background: #f8f8f8;
}
.shopping {
  display: flex;
  overflow-y: scroll;
}
.a {
  flex-basis: 41%;
  display: flex;
  align-items: center;
  height: 2rem;
  border: 0.01rem solid rgb(204, 204, 204);
  border-radius: 0.14rem;
  flex: 1;
  justify-content: center;
  margin-top: 0.2rem;
  overflow: hidden;
  margin-bottom: 0.2rem;
  margin-right: 0.2rem;
  padding: 0.1rem;
}
.imghhh {
  width: 80%;
  height: auto;
}
.shopping:nth-child(2) {
  font-size: 0.3rem;
}
.zuoyou {
  display: flex;
  font-size: 0.3rem;
  align-content: center;
  justify-content: space-between;
}
.bn {
  margin: 0.2rem 0 0.2rem 0;
}
</style>